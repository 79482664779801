import Axios, { AxiosError, AxiosRequestConfig } from "axios";

import { IFetchResponse } from "../types/IFetchResponse";
import { IMessages } from "../types/IMessages";

export abstract class BaseService {
    protected static axios = Axios.create({
        headers: {
            "Content-Type": "application/json",
        },
    });

    protected static getAxiosConfiguration(
        token?: string
    ): AxiosRequestConfig | undefined {
        
      if (token) {
            const config: AxiosRequestConfig = {
                headers: {
                    Authorization: "Bearer " + token,
                },
            };
            return config;
        }
    }

    static async getAll<TEntity>(
        path: string,
        token?: string
    ): Promise<IFetchResponse<TEntity[]>> {
        try {
            let response = await this.axios.get<TEntity[]>(
                path,
                BaseService.getAxiosConfiguration(token)
            );
            return {
                ok: response.status <= 299,
                statusCode: response.status,
                data: response.data,
            };
        } catch (err) {
            let error = err as AxiosError;
            return {
                ok: false,
                statusCode: error.response?.status ?? 500,
                messages: (error.response?.data as IMessages).messages,
            };
        }
    }

    static async get<TEntity>(path: string, token?: string): Promise<IFetchResponse<TEntity>> {
        try {
            let response = await this.axios.get<TEntity>(
                path,
                BaseService.getAxiosConfiguration(token)
            );
            return {
                ok: response.status <= 299,
                statusCode: response.status,
                data: response.data,
            };
        } catch (err) {
            let error = err as AxiosError;
            return {
                ok: false,
                statusCode: error.response?.status ?? 500,
                messages: (error.response?.data as IMessages).messages,
            };
        }
    }

    static async delete<TEntity>(
        path: string,
        token?: string
    ): Promise<IFetchResponse<TEntity[]>> {
        try {
            let response = await this.axios.delete<TEntity[]>(
                path,
                BaseService.getAxiosConfiguration(token)
            );
            return {
                ok: response.status <= 299,
                statusCode: response.status,
                data: response.data,
            };
        } catch (err) {
            let error = err as AxiosError;
            return {
                ok: false,
                statusCode: error.response?.status ?? 500,
                messages: (error.response?.data as IMessages).messages,
            };
        }
    }

    static async post<TEntity>(
        path: string,
        data: TEntity | null,
        token?: string
    ): Promise<IFetchResponse<TEntity[]>> {
        try {
            let response = await this.axios.post<TEntity[]>(
                path,
                data,
                BaseService.getAxiosConfiguration(token)
            );
            return {
                ok: response.status <= 299,
                statusCode: response.status,
                data: response.data,
            };
        } catch (err) {
            let error = err as AxiosError;
            return {
                ok: false,
                statusCode: error.response?.status ?? 500,
                messages: (error.response?.data as IMessages).messages,
            };
        }
    }

    static async put<TEntity>(
        path: string,
        data: TEntity,
        token?: string
    ): Promise<IFetchResponse<TEntity[]>> {
        try {
            let response = await this.axios.put<TEntity[]>(
                path,
                data,
                BaseService.getAxiosConfiguration(token)
            );
            return {
                ok: response.status <= 299,
                statusCode: response.status,
                data: response.data,
            };
        } catch (err) {
            let error = err as AxiosError;
            return {
                ok: false,
                statusCode: error.response?.status ?? 500,
                messages: (error.response?.data as IMessages).messages,
            };
        }
    }
}
