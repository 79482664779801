import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useAccount,
    useMsal,
} from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Navigate } from "react-router-dom";
import { checkRoles, refreshAccessToken } from "../helpers/auth";
import { BaseService } from "../services/base-service";
import { IBalance } from "../types/IBalance";
import { IUser } from "../types/IUser";
import { ApiBaseUrl } from "../configuration";
import {
    Container,
    Tab,
    Tabs,
    Typography,
    Box,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Table,
    Paper,
} from "@mui/material";
import { Helmet } from "react-helmet-async";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const Admin = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [balance, setBalance] = useState({} as IBalance);
    const [users, setUsers] = useState([] as IUser[]);
    const [key, setKey] = useState(0);

    const loadBalance = () => {
        if (!account || key !== 1) return;
        refreshAccessToken(instance, account).then((token) => {
            BaseService.get<IBalance>(ApiBaseUrl + "/admin/balance", token)
                .then((result) => {
                    if (result.ok && result.data) {
                        setBalance(result.data);
                    } else {
                        console.log("error 1");
                        // TODO: show error with response
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    const loadUsers = () => {
        if (!account || key !== 0) return;
        refreshAccessToken(instance, account).then((token) => {
            BaseService.getAll<IUser>(ApiBaseUrl + "/admin/users", token)
                .then((result) => {
                    if (result.ok && result.data) {
                        setUsers(result.data.sort((a, b) => a.login_date > b.login_date ?-1:1));
                    } else {
                        console.log("error 2");
                        // TODO: show error with response
                    }
                })
                .catch((e) => {
                    console.log("error");

                    // TODO: show error on load
                });
        });
    };

    useEffect(loadUsers, [account, instance, key]);
    useEffect(loadBalance, [account, instance, key]);

    const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 0, pt: 2 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    };

    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    return (
        <>
            <Container>
                <AuthenticatedTemplate>
                    <Helmet>
                        <title>Admin | pilet.info </title>
                    </Helmet>
                    {!checkRoles(account?.idTokenClaims, "admin") ? (
                        <Navigate to="/" />
                    ) : null}

                    <Typography mt={3} mb={3} variant="h4" component="h1">
                        Admin
                    </Typography>
                    <Tabs
                        value={key}
                        onChange={(
                            event: React.SyntheticEvent,
                            newValue: number
                        ) => setKey(newValue)}
                    >
                        <Tab label="Users" {...a11yProps(0)} />
                        <Tab label="Messente" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={key} index={0}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell variant="head">Id</TableCell>
                                    <TableCell variant="head">Nimi</TableCell>
                                    <TableCell variant="head">Email</TableCell>
                                    <TableCell variant="head">
                                        Viimati sisseloginud
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users &&
                                    users.map((user, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell>{user.id}</TableCell>
                                                <TableCell variant="head">
                                                    {user.name}
                                                </TableCell>
                                                <TableCell>
                                                    {user.email}
                                                </TableCell>
                                                <TableCell>
                                                    {user.login_date !==
                                                        null && (
                                                        <Moment
                                                            date={
                                                                user.login_date
                                                            }
                                                            format="DD.MM.Y HH:mm"
                                                        />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TabPanel>
                    <TabPanel value={key} index={1}>
                        <Paper sx={{p: 1}}>
                            <strong>Konto jääk: </strong>{" "}
                            {Math.round(Number(balance.balance) * 100) / 100} €
                        </Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell variant="head">
                                        Ettevõte
                                    </TableCell>
                                    <TableCell variant="head">Kood</TableCell>
                                    <TableCell variant="head">Hind</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {balance.prices !== undefined &&
                                    balance.prices.networks.map(
                                        (network, i) => {
                                            return (
                                                <TableRow key={i}>
                                                    <TableCell variant="head">
                                                        {network.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {network.mccmnc}
                                                    </TableCell>
                                                    <TableCell>
                                                        {Math.round(
                                                            Number(
                                                                network.price
                                                            ) * 1000
                                                        ) / 1000}{" "}
                                                        €
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }
                                    )}
                            </TableBody>
                        </Table>
                    </TabPanel>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Navigate to="/" />
                </UnauthenticatedTemplate>
            </Container>
        </>
    );
};

export default Admin;
