import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useAccount,
    useMsal,
} from "@azure/msal-react";
import { Container, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Moment from "react-moment";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router-dom";
import { ApiBaseUrl } from "../configuration";
import { checkRoles, refreshAccessToken } from "../helpers/auth";
import { BaseService } from "../services/base-service";
import { ILog } from "../types/ILog";
import PullToRefresh from "react-simple-pull-to-refresh";

const Logs = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [logs, setLogs] = useState([] as ILog[]);

    const loadLogs = () => {
        refreshAccessToken(instance, account).then((token) => {
            BaseService.getAll<ILog>(ApiBaseUrl + "/logs", token)
                .then((result) => {
                    if (result.ok && result.data) {
                        setLogs(result.data);
                    } else {
                        console.log("error 2");
                        // TODO: show error with response
                    }
                })
                .catch((e) => {
                    console.log("error");

                    // TODO: show error on load
                });
        });
    };
    const handleRefresh = async (): Promise<any> => {
        loadLogs();
    }

    useEffect(loadLogs, [account, instance]);

    return (
        <>
            <Container>
                <PullToRefresh onRefresh={handleRefresh} maxPullDownDistance={200} resistance={2} pullDownThreshold={100}>
                    <AuthenticatedTemplate>
                        <Helmet>
                            <title>Logid | pilet.info </title>
                        </Helmet>
                        {!checkRoles(account?.idTokenClaims, "admin") ? (
                            <Navigate to="/" />
                        ) : null}
                        <Typography mt={3} mb={3} variant="h4" component="h1">
                            Logid
                        </Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Aeg</TableCell>
                                    <TableCell>Tase</TableCell>
                                    <TableCell>Allikas</TableCell>
                                    <TableCell>Andmed</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {logs &&
                                    logs.map((item, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                className={
                                                    item.level === "ERROR"
                                                        ? "lead text-error"
                                                        : "lead"
                                                }
                                            >
                                                <TableCell>
                                                    <Moment
                                                        format="D.MM.Y HH:mm:ss"
                                                        date={
                                                            item.created_date +
                                                            "+0000"
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {item.level}
                                                </TableCell>
                                                <TableCell>
                                                    {item.source}
                                                </TableCell>
                                                <TableCell>
                                                    {item.data}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </AuthenticatedTemplate>
                </PullToRefresh>
                <UnauthenticatedTemplate>
                    <Navigate to="/" />
                </UnauthenticatedTemplate>
            </Container>
        </>
    );
};

export default Logs;
