import { useAccount, useMsal } from "@azure/msal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Button,
    FormControl,
    Paper,
    Stack,
    TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { ApiBaseUrl } from "../configuration";
import { refreshAccessToken } from "../helpers/auth";
import { BaseService } from "../services/base-service";
import { IPhone } from "../types/IPhone";
import { toast } from "react-hot-toast";

const Phone = (props: { phone?: IPhone, onDelete: Function, onRefresh: Function}) => {
    const { instance, accounts } = useMsal();
    const [phone, setPhone] = useState(props.phone);
    const account = useAccount(accounts[0] || {});
    const [phoneNr, setPhoneNr] = useState(phone?.phone);
    const [comment, setComment] = useState(phone?.comment);
    const phoneRef = useRef(null);
    const commentRef = useRef(null);

    const submit = () => {
        if (phoneNr === phone?.phone && comment === phone?.comment) {
            toast("Nothing to save");
            return;
        }

        if (phone === undefined || phone.id === undefined) insert();
        else update();
    };

    const insert = () => {
        // TODO: show validation message in case of errors

        if (phoneNr === undefined || phoneNr!.length < 5) {
            toast.error("Telefoninumber liiga lühike!")
            return;
        }

        refreshAccessToken(instance, account).then((token) => {
            let p = {
                phone: phoneNr,
                comment: comment,
            } as IPhone;
            var data = [p];

            BaseService.post<IPhone[]>(
                ApiBaseUrl + "/accounts/phones",
                data,
                token
            )
                .then((result) => {
                    if (result.ok && result.data) {
                        toast.success("Number lisatud!");
                        props.onRefresh();
                    } else {
                        console.log("error");
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    const update = () => {
        if (phoneNr === undefined || phoneNr!.length < 5) {
            toast.error("Telefoninumber liiga lühike!");
            return;
        }

        refreshAccessToken(instance, account).then((token) => {
            var data = {
                id: phone!.id,
                phone: phoneNr,
                comment: comment,
            } as IPhone;

            BaseService.put<IPhone>(
                ApiBaseUrl + "/accounts/phones/" + phone!.id,
                data,
                token
            )
                .then((result) => {
                    if (result.ok && result.statusCode === 200) {
                        setPhone(data);
                        toast.success("Salvestatud!");
                    } else {
                        console.log("error");
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    const removePhone = (id: number) => {
        if(phone!.id === undefined) { 
            props.onDelete();
            return;
        }
        
        refreshAccessToken(instance, account).then((token) => {
            BaseService.delete<IPhone>(
                ApiBaseUrl + "/accounts/phones/" + id,
                token
            )
                .then((result) => {
                    if (result.ok && result.statusCode === 200) {
                        toast.success("Number kustutatud");
                        props.onDelete();
                    } else {
                        console.log("error");
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    return (
        <>
            <Paper elevation={3} sx={{ marginX: -2 }}>
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems={{ md: "center" }}
                    spacing={2}
                    padding={2}
                    marginTop={2}
                    flexGrow={1}
                >
                    <FormControl>
                        <TextField
                            variant="standard"
                            ref={phoneRef}
                            value={phoneNr}
                            onChange={(e) => setPhoneNr(e.target.value)}
                            label="Telefoni nr"
                        />
                    </FormControl>

                    <FormControl>
                        <TextField
                            variant="standard"
                            ref={commentRef}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            label="Kommentaar"
                        />
                    </FormControl>
                    <Button
                        onClick={submit}
                        variant="contained"
                        aria-label="delete"
                    >
                        Salvesta
                    </Button>
                    <FontAwesomeIcon
                        icon="trash-alt"
                        role="button"
                        onClick={(e) => {
                            e.preventDefault();
                            removePhone(phone!.id);
                        }}
                    />
                </Stack>
            </Paper>
        </>
    );
};

export default Phone;
