import { AuthenticatedTemplate, UnauthenticatedTemplate, useAccount, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { Navigate, Link } from "react-router-dom";
import { BaseService } from "../services/base-service";
import { ITrip } from "../types/ITrip";
import { IStorageData } from "../types/IStorageData";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { refreshAccessToken } from "../helpers/auth";
import { IPhone } from "../types/IPhone";
import { IOrder } from "../types/IOrder";
import { useNavigate } from "react-router-dom";
import { ApiBaseUrl } from "../configuration";
import { Button, Container, FormControl, InputLabel, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography, MenuItem } from "@mui/material";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";

const Confirm = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const navigate = useNavigate();
    const [phones, setPhones] = useState([] as IPhone[]);
    const [selectedPhone, setSelectedPhone] = useState("");
    const [orderTrips, setOrderTrips] = useState([] as ITrip[]);

    const submitClick = (e: Event) => {
        e.preventDefault();

        if (orderTrips.length === 0) return;

        refreshAccessToken(instance, account).then((token) => {
            let data = {
                phonenr: selectedPhone,
                trips: orderTrips,
            } as IOrder;

            BaseService.post<IOrder>(ApiBaseUrl + "/trips", data, token)
                .then((result) => {
                    if (result.ok && result.statusCode === 200) {
                        setOrderTrips([]);
                        const data = localStorage.getItem("orderData");
                        if (data) {
                            let orderData: IStorageData = JSON.parse(data);
                            orderData.selectedTrips = [];
                            localStorage.setItem(
                                "orderData",
                                JSON.stringify(orderData)
                            );
                        }
                        toast.success("Tellimus salvestatud!");
                        navigate("/history");
                    } else {
                        console.log("error");
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    const loadPhones = () => {
        if(!account) return;
        refreshAccessToken(instance, account).then((token) => {
            BaseService.getAll<IPhone>(
                ApiBaseUrl + "/accounts/phones",
                token
            )
                .then((result) => {
                    if (result.ok && result.data) {
                        setPhones(result.data);
                        setSelectedPhone(result.data[0].id.toString());
                    } else {
                        console.log("error");
                        // TODO: show error with response
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    const loadData = () => {
        const data = localStorage.getItem("orderData");
        if (data) {
            let orderData: IStorageData = JSON.parse(data);
            setOrderTrips(orderData.selectedTrips);
        }
    };

    useEffect(loadPhones, [account, instance]);
    useEffect(loadData, []);

    return (
        <>
        <Container>
        <AuthenticatedTemplate>
            <Helmet>
                <title>Kinnita tellimus | pilet.info </title>
            </Helmet>
                <Typography mt={3} mb={3} variant="h4" component="h1">Tellimus</Typography>
                <FormControl>
                    <InputLabel id="phone-label">Telefon:</InputLabel>
                    <Select
                        label="Telefon:"
                        labelId="phone-label"
                        value={selectedPhone}
                        onChange={(e) => {
                            setSelectedPhone(e.target.value);
                        }}
                    >
                        {phones !== undefined &&
                            phones.map((phone, i) => {
                                return (
                                    <MenuItem key={i} value={phone.id}>
                                        {phone.phone} - {phone.comment}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Aeg</TableCell>
                            <TableCell>Suund</TableCell>
                            <TableCell>Paat</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orderTrips.map((trip) => {
                            return (
                                <TableRow 
                                    key={trip.uid}
                                    hover
                                    >
                                    <TableCell>
                                        <Moment
                                            format="DD.MM.Y HH:mm"
                                            date={trip.dtstart}
                                        />
                                    </TableCell>
                                    <TableCell>{trip.direction.name}</TableCell>
                                    <TableCell>{trip.ship.code}</TableCell>
                                    <TableCell>
                                        <FontAwesomeIcon
                                            icon="trash-alt"
                                            role="button"
                                            onClick={(e) => {
                                                // TODO: Doesn't remove from storage
                                                e.preventDefault();
                                                setOrderTrips(
                                                    orderTrips.filter(
                                                        (t) => trip.uid !== t.uid
                                                    )
                                                );
                                            }}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <div>
                    <Button 
                        sx={{marginTop: "0.8rem", textDecoration: "none"}}
                        variant="outlined" 
                        size="large"
                        to={'/order'}
                        component={Link}>
                        Tagasi
                    </Button>
                    <Button
                        sx={{float: "right", marginTop: "0.8rem"}}
                        variant="contained"
                        size="large"
                        disabled={orderTrips.length <= 0}
                        onClick={(e) => submitClick(e.nativeEvent)}
                    >
                        Kinnita
                    </Button>
                </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Navigate to="/" />
                </UnauthenticatedTemplate>
            </Container>
        </>
    );
};

export default Confirm;
