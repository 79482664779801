import React from "react";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { render } from 'react-dom';

import "./index.css";
import App from "./App";

import 'jquery';
import 'popper.js';
import 'font-awesome/css/font-awesome.min.css';
import etLocale from "date-fns/locale/et";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Component
const AppProvider = () => (
    <React.StrictMode>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={etLocale}>
            <App />
        </LocalizationProvider>
    </React.StrictMode>
);

const container = document.getElementById('root');
render(<AppProvider />, container);

// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();