import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useAccount,
    useMsal,
} from "@azure/msal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Box,
    Container,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Moment from "react-moment";
import { Navigate } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import { ApiBaseUrl } from "../configuration";
import { checkRoles, refreshAccessToken } from "../helpers/auth";
import { BaseService } from "../services/base-service";
import { IHistory } from "../types/IHistory";
import { IOrder } from "../types/IOrder";

const History = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [history, setHistory] = useState([] as IHistory[]);
    const [showAll, setShowAll] = useState("0");

    const repeatOrder = (item: IHistory) => {
        refreshAccessToken(instance, account).then((token) => {
            BaseService.post<IOrder>(
                ApiBaseUrl + "/trips/" + item.id + "/redo",
                null,
                token
            )
                .then((result) => {
                    if (result.ok && result.statusCode === 200) {
                        loadHistory();
                    } else {
                        console.log("error");
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    const deleteOrder = (item: IHistory) => {
        refreshAccessToken(instance, account).then((token) => {
            BaseService.delete<IOrder>(ApiBaseUrl + "/trips/" + item.id, token)
                .then((result) => {
                    if (result.ok && result.statusCode === 200) {
                        loadHistory();
                    } else {
                        console.log("error");
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    const loadHistory = () => {
        if (!account) return;

        refreshAccessToken(instance, account).then((token) => {
            var url = ApiBaseUrl + "/trips";
            if (showAll === "1") url += "?all=1";

            BaseService.getAll<IHistory>(url, token)
                .then((result) => {
                    if (result.ok && result.data) {
                        setHistory(result.data);
                    } else {
                        console.log("error");
                        // TODO: show error with response
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    const handleRefresh = async (): Promise<any> => {
        loadHistory();
    }

    useEffect(loadHistory, [showAll, instance, account]);

    return (
        <>
        <PullToRefresh onRefresh={handleRefresh} pullingContent="" maxPullDownDistance={200} resistance={2} pullDownThreshold={100}>
            <Container>
                <AuthenticatedTemplate>
                    <Helmet>
                        <title>Tellimuste ajalugu | pilet.info </title>
                    </Helmet>
                    <Typography mt={3} mb={3} variant="h4" component="h1">
                        Tellimuste ajalugu
                    </Typography>
                    {checkRoles(account?.idTokenClaims, "admin") && (
                        <FormControl>
                            <InputLabel id="filter-label">Filter:</InputLabel>
                            <Select
                                label="Filter:"
                                value={showAll}
                                onChange={(e) => setShowAll(e.target.value)}
                                labelId="filter-label"
                            >
                                <MenuItem value="0">Minu</MenuItem>
                                <MenuItem value="1">Kõik</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Praami aeg</TableCell>
                                <TableCell>Suund</TableCell>
                                <TableCell>Telefon</TableCell>
                                {checkRoles(account?.idTokenClaims, "admin") &&
                                    showAll === "1" && (
                                        <TableCell>Kasutaja</TableCell>
                                    )}
                                <TableCell>SMS aeg</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history !== undefined &&
                                history
                                    .sort((a, b) => {
                                        return (
                                            new Date(b.dtstart).getTime() -
                                                new Date(a.dtstart).getTime() ||
                                            new Date(b.date_created).getTime() -
                                                new Date(
                                                    a.date_created
                                                ).getTime()
                                        );
                                    })
                                    .map((item, i) => {
                                        return (
                                            <TableRow
                                                key={i}
                                                className={
                                                    item.date_canceled
                                                        ? "lead text-muted text-deleted"
                                                        : "lead"
                                                }
                                            >
                                                <TableCell>
                                                    <Moment
                                                        format="D.MM.Y HH:mm"
                                                        date={
                                                            item.dtstart +
                                                            "+0000"
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {item.direction}
                                                </TableCell>
                                                <TableCell>
                                                    {item.phonenr}
                                                </TableCell>
                                                {checkRoles(
                                                    account?.idTokenClaims,
                                                    "admin"
                                                ) &&
                                                    showAll === "1" && (
                                                        <TableCell>
                                                            {item.user_name ==
                                                            null
                                                                ? item.user_id
                                                                : item.user_name}
                                                        </TableCell>
                                                    )}
                                                <TableCell>
                                                    {item.date_complete && (
                                                        <>
                                                            <Moment
                                                                format="D.MM.Y HH:mm"
                                                                date={
                                                                    item.date_complete +
                                                                    "+0000"
                                                                }
                                                            />
                                                            <Box sx={{paddingLeft: 1}} component="span" >
                                                            {item.date_delivered &&
                                                                (
                                                                    <FontAwesomeIcon
                                                                        icon="check"
                                                                        color={(item.message_status === "DELIVRD")?"green":"red"}
                                                                        title={
                                                                            item.date_delivered +
                                                                            " - " +
                                                                            item.message_status
                                                                        }
                                                                    />
                                                                )}
                                                            </Box>
                                                        </>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {new Date(
                                                        item.dtstart.replace(
                                                            /-/g,
                                                            "/"
                                                        )
                                                    ) > new Date() &&
                                                        !item.date_complete &&
                                                        !item.date_canceled && (
                                                            <IconButton
                                                                size="small"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.preventDefault();
                                                                    deleteOrder(
                                                                        item
                                                                    );
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon="trash-alt" />
                                                            </IconButton>
                                                        )}
                                                    {new Date(
                                                        item.dtstart.replace(
                                                            /-/g,
                                                            "/"
                                                        )
                                                    ) > new Date() && ( //item.complete &&
                                                        <IconButton
                                                            size="small"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                repeatOrder(
                                                                    item
                                                                );
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon="redo" />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                        </TableBody>
                    </Table>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Navigate to="/" />
                </UnauthenticatedTemplate>
            </Container>
            </PullToRefresh>
        </>
    );
};

export default History;
