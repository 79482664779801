import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Navigate, useNavigate } from "react-router-dom";
import { BaseService } from "../services/base-service";
import { IDirections } from "../types/IDirections";
import { ITrips } from "../types/ITrips";
import { ITrip } from "../types/ITrip";

import "flatpickr/dist/themes/light.css";

import { IStorageData } from "../types/IStorageData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Table,
    Button,
    Container,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Stack,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    FormControl,
    Checkbox,
    Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Helmet } from "react-helmet-async";
import PullToRefresh from "react-simple-pull-to-refresh";

const Order = () => {
    const navigate = useNavigate();
    const [directions, setDirections] = useState({} as IDirections);
    const [selectedDirection, setSelectedDirection] = useState("RH");
    const [selectedDate, setSelectedDate] = useState<Date>();
    const [orderTrips, setOrderTrips] = useState([] as ITrip[]);
    const [trips, setTrips] = useState({} as ITrips);

    const nextPage = (e: Event) => {
        e.preventDefault();
        if (orderTrips.length > 0) navigate("/order/confirm");
    };

    useEffect(() => {
        const data = localStorage.getItem("orderData");
        if (data) {
            let orderData: IStorageData = JSON.parse(data);
            loadStorage(orderData);
        } else {
            setSelectedDate(new Date());
            loadDirections();
        }
        // eslint-disable-next-line
    }, []);

    const loadStorage = (orderData: IStorageData) => {
        let selDate = new Date(orderData.selectedDate);
        if (selDate.getDate() < new Date().getDate()) selDate = new Date();

            setSelectedDirection(orderData.selectedDirection);
            setSelectedDate(selDate);
            loadDirections();
            if(orderData.directions.totalCount > 0) {
                setDirections(orderData.directions);
            } else {

            }
            setOrderTrips(orderData.selectedTrips.filter((trip) => new Date(trip.dtend) > new Date()));
    }

    const loadDirections = () => {
        BaseService.get<IDirections>("https://www.praamid.ee/online/directions")
                .then((result) => {
                    if (result.ok && result.data) {
                        setDirections(result.data);
                    } else {
                        // TODO: show error with response
                    }
                })
                .catch(() => {
                    // TODO: show error on load
                });
    }

    const saveStorage = () => {
        let orderData: IStorageData = {
            selectedDate: selectedDate!,
            selectedDirection: selectedDirection,
            selectedTrips: orderTrips,
            directions: directions,
        };

        localStorage.setItem("orderData", JSON.stringify(orderData));
        return orderData;
    };

    const save = () => {
        saveStorage();
    };

    const loadData = () => {
        if(!selectedDate)
            return;
        BaseService.get<ITrips>(
            "https://www.praamid.ee/online/events?direction=" +
                selectedDirection +
                "&departure-date=" +
                formatDate(selectedDate)
        )
            .then((result) => {
                if (result.ok && result.data) {
                    setTrips(result.data);
                    saveStorage();
                } else {
                    // TODO: show error with response
                }
            })
            .catch(() => {
                // TODO: show error on load
            });
    };

    const updateTrip = (trip: ITrip) => {
        if(trip.capacities
            .sv !== 0)
            return;

        if (orderTrips.find((t) => t.uid === trip.uid))
            setOrderTrips(orderTrips.filter((t) => t.uid !== trip.uid));
        else setOrderTrips([...orderTrips, trip]);
    };

    const formatDate = (date: Date) => {
        return date.getFullYear() 
            + "-" + ("0" + (date.getMonth() +1)).slice(-2) 
            + "-" + ("0" + date.getDate()).slice(-2);
    }
    const handleRefresh = async (): Promise<any> => {
        loadData();
    }

    // eslint-disable-next-line
    useEffect(loadData, [selectedDirection, selectedDate]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(save, [orderTrips]);

    return (
        <>
            <PullToRefresh onRefresh={handleRefresh} pullingContent="" maxPullDownDistance={200} resistance={2} pullDownThreshold={100}>
                <AuthenticatedTemplate>
                    <Helmet>
                        <title>Tellimus | pilet.info </title>
                    </Helmet>
                    <Container>
                        <Typography mt={3} mb={3} variant="h4" component="h1">
                            Tellimus
                        </Typography>
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={{ xs: 2, sm: 2, md: 4 }}
                        >
                            <FormControl>
                                <DatePicker
                                    minDate={
                                        new Date(
                                            new Date().getFullYear(),
                                            new Date().getMonth(),
                                            new Date().getDate()
                                        )
                                    }
                                    value={selectedDate}
                                    label="Kuupäev:"
                                    inputFormat="dd.MM.yyyy"
                                    mask="__.__.____"
                                    showDaysOutsideCurrentMonth
                                    closeOnSelect
                                    showToolbar={false}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                    onAccept={(date) => {
                                        setSelectedDate(date!);
                                        //loadData(selectedDirection, dateStr);
                                    }}
                                    onChange={(date) => {}}
                                />
                            </FormControl>
                            <FormControl>
                                <InputLabel id="direction-label">
                                    Suund:
                                </InputLabel>
                                <Select
                                    label="Suund:"
                                    labelId="direction-label"
                                    value={selectedDirection}
                                    onChange={(e) => {
                                        setSelectedDirection(e.target.value);
                                    }}
                                >
                                    {directions.items !== undefined &&
                                        directions.items.map((direction, i) => {
                                            return (
                                                <MenuItem
                                                    key={i}
                                                    value={direction.code}
                                                >
                                                    {direction.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                            <div className="col-6">
                                Valitud reisid: {orderTrips.length}
                                <br />
                                <div className="text-end float-end">
                                    {orderTrips.map((t, i) => {
                                        //TODO: Move to component
                                        return (
                                            <div key={i}>
                                                <Moment
                                                    date={t.dtstart}
                                                    format="DD.MM.YY HH:mm"
                                                />{" "}
                                                - {t.direction.name} &nbsp;
                                                <FontAwesomeIcon
                                                    icon="trash-alt"
                                                    role="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setOrderTrips(
                                                            orderTrips.filter(
                                                                (trip) =>
                                                                    trip.uid !==
                                                                    t.uid
                                                            )
                                                        );
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Stack>
                        <Table stickyHeader sx={{ marginTop: "1rem" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>Kellaaeg</TableCell>
                                    <TableCell>Vabu kohti</TableCell>
                                    <TableCell>Paat</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trips.items !== undefined &&
                                    trips.items
                                        .sort((a, b) => {
                                            return (
                                                new Date(a.dtstart).getTime() -
                                                new Date(b.dtstart).getTime()
                                            );
                                        })
                                        .map((trip, i) => {
                                            // TODO: Make whole row clickable
                                            trip.direction =
                                                directions.items.filter(
                                                    (d) =>
                                                        d.code ===
                                                        selectedDirection
                                                )[0];
                                            return (
                                                <TableRow
                                                    key={i}
                                                    hover
                                                    onClick={() =>
                                                        updateTrip(trip)
                                                    }
                                                >
                                                    <TableCell
                                                        sx={{
                                                            align: "left",
                                                            maxWidth: 20,
                                                        }}
                                                    >
                                                        <Checkbox
                                                            sx={{
                                                                marginLeft: -2,
                                                            }}
                                                            checked={
                                                                orderTrips.filter(
                                                                    (t) =>
                                                                        t.uid ===
                                                                        trip.uid
                                                                ).length > 0
                                                            }
                                                            id={"cb_" + i}
                                                            disabled={
                                                                trip.capacities
                                                                    .sv !== 0
                                                            }
                                                            onChange={(e) => {
                                                                updateTrip(
                                                                    trip
                                                                );
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{ minWidth: 120 }}
                                                    >
                                                        <span className="d-inline">
                                                            <Moment
                                                                format="HH:mm"
                                                                date={
                                                                    new Date(
                                                                        trip.dtstart
                                                                    )
                                                                }
                                                            />{" "}
                                                            <FontAwesomeIcon
                                                                icon="chevron-right"
                                                                size="xs"
                                                                color="#AAA"
                                                            />{" "}
                                                            <Moment
                                                                format="HH:mm"
                                                                date={
                                                                    new Date(
                                                                        trip.dtend
                                                                    )
                                                                }
                                                            />
                                                        </span>
                                                        <span className="text-muted small m-4 mb-0 mt-0">
                                                            {trip
                                                                .transportationType
                                                                .code === "O"
                                                                ? "Ohtlik veos"
                                                                : ""}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        {trip.capacities.sv}
                                                    </TableCell>
                                                    <TableCell>
                                                        {trip.ship.code}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                            </TableBody>
                        </Table>

                        <Button
                            sx={{ marginTop: "1rem", float: "right", mb: 3 }}
                            variant="contained"
                            size="large"
                            disabled={orderTrips.length <= 0}
                            onClick={(e) => nextPage(e.nativeEvent)}
                        >
                            Edasi
                        </Button>
                    </Container>
                </AuthenticatedTemplate>
            </PullToRefresh>
            <UnauthenticatedTemplate>
                <Navigate to="/" />
            </UnauthenticatedTemplate>
        </>
    );
};

export default Order;
