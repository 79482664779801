import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useAccount,
    useMsal,
} from "@azure/msal-react";
import {
    Box,
    Button,
    Container,
    FormControl,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Phone from "../components/phone";
import { ApiBaseUrl } from "../configuration";
import { refreshAccessToken } from "../helpers/auth";
import { BaseService } from "../services/base-service";
import { IPhone } from "../types/IPhone";
import { Helmet } from "react-helmet-async";
import { ISubscription } from "../types/ISubsription";
import { checkSubscription, requestForToken } from "../notification";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Account = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [phones, setPhones] = useState([] as IPhone[]);
    const [subscriptions, setSubscriptions] = useState([] as ISubscription[]);

    const loadPhones = () => {
        if (!account) return;
        refreshAccessToken(instance, account).then((token) => {
            BaseService.getAll<IPhone>(ApiBaseUrl + "/accounts/phones", token)
                .then((result) => {
                    if (result.ok && result.data) {
                        setPhones(result.data);
                    } else {
                        console.log("error");
                        // TODO: show error with response
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    const loadSubscriptions = () => {
        if (!account) return;

        // featureflag:
        if (account.username !== "tanel@atb.ee" && account.username !== "pilet@pilet.info") return;

        refreshAccessToken(instance, account).then((token) => {
            BaseService.getAll<ISubscription>(
                ApiBaseUrl + "/accounts/subscriptions",
                token
            )
                .then(async (result) => {
                    if (result.ok && result.data) {
                        let subscription = await checkSubscription();
                        if(subscription !== null && result.data.length > 0) {
                            result.data.forEach((s) => {
                                if(JSON.parse(s.subscription).keys.auth === subscription!.keys!.auth) {
                                    s.this = true;
                                }
                            })
                        }
                        setSubscriptions(result.data);
                    } else {
                        console.log("error");
                        // TODO: show error with response
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    const subscribe = async () => {
        let sub = await requestForToken();
        let newSub = {
            subscription: JSON.stringify(sub),
            type: navigator.userAgent,
        } as ISubscription;
        refreshAccessToken(instance, account).then((token) => {
            BaseService.post<ISubscription>(
                ApiBaseUrl + "/accounts/subscriptions",
                newSub,
                token
            )
                .then((result) => {
                    if (result.ok) {
                        toast.success("Veebilehitseja lisatud!");
                        loadSubscriptions();
                    } else {
                        console.log("error");
                        // TODO: show error with response
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    const unsubscribe = (id: number) => {
        refreshAccessToken(instance, account).then((token) => {
            BaseService.delete(
                ApiBaseUrl + "/accounts/subscriptions/" + id,
                token
            )
                .then((result) => {
                    if (result.ok) {
                        toast.success("Veebilehitseja kustutatud!");
                        loadSubscriptions();
                    } else {
                        console.log("error");
                        // TODO: show error with response
                    }
                })
                .catch(() => {
                    console.log("error");
                    // TODO: show error on load
                });
        });
    };

    useEffect(loadPhones, [account, instance]);
    useEffect(loadSubscriptions, [account, instance]);

    return (
        <>
            <Container>
                <Helmet>
                    <title>Minu konto | pilet.info </title>
                </Helmet>
                <AuthenticatedTemplate>
                    <Typography mt={3} mb={3} variant="h4" component="h1">
                        Minu konto
                    </Typography>

                    <Stack
                        direction={{ xs: "column", lg: "row" }}
                        flexGrow={1}
                        spacing={2}
                        justifyContent="space-around"
                    >
                        <Stack spacing={2} direction="column" flexGrow={0.6}>
                            <FormControl>
                                <TextField
                                    value={account?.name}
                                    label="Nimi:"
                                    disabled
                                    variant="standard"
                                />
                            </FormControl>
                            <FormControl>
                                <TextField
                                    value={account?.username}
                                    label="Email:"
                                    disabled
                                    variant="standard"
                                />
                            </FormControl>
                        </Stack>
                        <Stack alignItems={{ xs: "stretch", md: "flex-start" }}>
                            <Typography variant="h5" >Telefoninumbrid</Typography>
                            {phones.length > 0 &&
                                phones.map((phone, i) => {
                                    return (
                                        <Box key={i} component="div">
                                            <Phone
                                                phone={phone}
                                                onDelete={() => {
                                                    setPhones(
                                                        phones.filter(
                                                            (a) =>
                                                                a.id !==
                                                                phone.id
                                                        )
                                                    );
                                                }}
                                                onRefresh={() => {
                                                    loadPhones();
                                                }}
                                            />
                                        </Box>
                                    );
                                })}
                            {!phones.find((p) => p.id === undefined) && (
                                <Button
                                    onClick={() => {
                                        if (
                                            !phones.find(
                                                (p) => p.id === undefined
                                            )
                                        )
                                            setPhones([
                                                ...phones,
                                                {} as IPhone,
                                            ]);
                                    }}
                                >
                                    + Lisa uus
                                </Button>
                            )}
                            {(account?.username === "tanel@atb.ee" || account?.username === "pilet@pilet.info") && (
                                <>
                                <Typography variant="h5" paddingTop={1}>Seadmed</Typography>
                                    {subscriptions.length > 0 &&
                                        subscriptions.map((subscription, i) => {
                                            return (
                                                <Box key={i} component="div" maxWidth={570}>
                                                    <Paper
                                                        elevation={3}
                                                        sx={{ marginX: -2 }}
                                                    >
                                                        <Stack
                                                            direction={{
                                                                xs: "column",
                                                                md: "row",
                                                            }}
                                                            alignItems={{
                                                                md: "center",
                                                            }}
                                                            spacing={2}
                                                            padding={2}
                                                            marginTop={2}
                                                            flexGrow={1}
                                                        >
                                                            <Typography style={{fontWeight: subscription.this?"bold":"regular"}}>
                                                                {
                                                                    subscription.type + (subscription.this ? " (Käesolev seade)":"")
                                                                }
                                                                
                                                            </Typography>
                                                            <FontAwesomeIcon
                                                                icon="trash-alt"
                                                                role="button"
                                                                onClick={() => {
                                                                    unsubscribe(
                                                                        subscription.id
                                                                    );
                                                                }}
                                                            />
                                                        </Stack>
                                                    </Paper>
                                                </Box>
                                            );
                                        })}
                                    {!subscriptions.filter((s) => s.this).length && <>
                                        <Button onClick={subscribe}>
                                            + Lisa praegune seade
                                        </Button>
                                    </>}
                                </>
                            )}
                        </Stack>
                    </Stack>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Navigate to="/" />
                </UnauthenticatedTemplate>
            </Container>
        </>
    );
};

export default Account;
