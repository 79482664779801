import { AuthenticationResult, IPublicClientApplication } from "@azure/msal-browser";
import { AccountInfo } from "@azure/msal-common";
import toast from "react-hot-toast";
import { ApiBaseUrl } from "../configuration";
import { BaseService } from "../services/base-service";
import { IUser } from "../types/IUser";

const loginRequest = {
  scopes: ["openid", "profile", "api://praamipiletid/read"]
}

const submitLogin = (authResult: AuthenticationResult) => {
// TODO: Refactor to use same method than in Menu
  BaseService.post<IUser>(ApiBaseUrl + "/accounts/login", null, authResult.accessToken)
  .then((result) => {
      if (result.ok && result.data) {
          toast.success("Sisselogimine õnnestus");
      } else {
          console.log("error");
          // TODO: show error with response
      }
  })
  .catch(() => {
      console.log("error");
      // TODO: show error on load
  });
}

export function checkRoles(claims: object | undefined, role: string): boolean {

    if(claims === null || claims === undefined) {
        return false;
    }
    let roles: string[] = [];
    Object.entries(claims).forEach((key) => {
        if(key[0] === "roles") {
          roles = key[1];
        }
    });
    if(roles.includes(role))
        return true;

    return false;
}

export async function refreshAccessToken(instance: IPublicClientApplication, account: AccountInfo | null) {
    try {
      const token = await instance.acquireTokenSilent({
        account: account || undefined,
        scopes: ["openid", "profile", "api://praamipiletid/read"]
      });
      return token.accessToken;
    } catch (error) {
      console.log("Could not refresh token for account: ", account," Error: ", error);
      instance.loginPopup(loginRequest).then((authResult) => submitLogin(authResult));
    }
/*
    const tokenResponse = await instance.acquireTokenSilent({
      account: account || undefined,
      scopes: ["openid", "profile", "api://praamipiletid/read"],
      forceRefresh: false
    }).catch(async (error) => {
      if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          return await instance.acquireTokenPopup({
            loginHint: account?.username || undefined,
            scopes: ["openid", "profile", "api://praamipiletid/read"]
          }).catch(error => {
            console.log("should logout?", error);
          });
      }
    });
    return (tokenResponse as AuthenticationResult).accessToken;*/
  };