import { Container } from "@mui/material";
import { Helmet } from "react-helmet-async";

const NotFound = () => {
    

  return <>
    <Helmet>
        <title>404 | pilet.info </title>
    </Helmet>
    <Container>
      <div className="mt-5">
        <h1 className="display-3 mt-4">404!</h1>
        <h2>Lehte ei leitud!</h2>
      </div>
    </Container>
  </>
}

export default NotFound;