import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import "./App.css";
import Menu from "./components/menu";
import Account from "./containers/account";
import Home from "./containers/home";
import Order from "./containers/order";
import History from "./containers/history";
import Logs from "./containers/logs";
import Confirm from "./containers/confirm";
import Admin from "./containers/admin";
import NotFound from "./containers/not-found";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faTrashAlt,
    faCheck,
    faExclamation,
    faRedo,
    faChevronRight,
    faBars,
    faHouse,
    faArrowRightFromBracket,
    faUser,
    faClockRotateLeft,
    faShip,
    faDatabase,
    faHammer,
} from "@fortawesome/free-solid-svg-icons";
import {
    Box,
    CssBaseline,
    ThemeProvider,
    useMediaQuery,
} from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { BaseUrl } from "./configuration";
import { MsalProvider } from "@azure/msal-react";
import * as msal from "@azure/msal-browser";
import { HelmetProvider } from "react-helmet-async";
import ReactGA from 'react-ga4';
import { Toaster } from 'react-hot-toast';

function App() {

    const TRACKING_ID = "G-W7TYJHX5NM"; // OUR_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);
    // MSAL configuration
        const configuration: msal.Configuration = {
        auth: {
            clientId: "d8bab812-64e5-46ff-855f-868c721c9932",
            authority:
                "https://login.microsoftonline.com/ca797089-7e98-4b8c-bf72-48b3dc4ec873",
            redirectUri: BaseUrl,
        },
        cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
        },
    };

    const pca = new msal.PublicClientApplication(configuration);

    library.add(faTrashAlt, faCheck, faExclamation, faRedo, faChevronRight, faBars, faHouse, faArrowRightFromBracket, faUser, faClockRotateLeft, faShip, faDatabase, faHammer);

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    let theme = React.useMemo(
        () => createTheme({
                palette: {
                    mode: prefersDarkMode ? "dark" : "light",
                    primary: {
                        main: '#2f333f',
                      },
                      secondary: {
                        main: '#f50057',
                      },
                },
                typography: {
                    fontSize: 16
                }
            }), 
        [prefersDarkMode]
    );
    theme = responsiveFontSizes(theme);

    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <HelmetProvider>
                    <MsalProvider instance={pca}>
                        <BrowserRouter>
                            <Box
                                component="main"
                                sx={{ display: 'flex', flexGrow: 1, p: {xs: 0,  md: 2}, mt: {xs: 6, md: 5} }}
                            >
                                <Toaster position="top-center"/>
                                <Menu />
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route
                                        path="/order/confirm"
                                        element={<Confirm />}
                                    />
                                    <Route path="/order" element={<Order />} />
                                    <Route path="/history" element={<History />} />
                                    <Route path="/account" element={<Account />} />
                                    <Route path="/logs" element={<Logs />} />
                                    <Route path="/admin" element={<Admin />} />
                                    <Route element={<NotFound />} />
                                </Routes>
                            </Box>
                        </BrowserRouter>
                    </MsalProvider>
                </HelmetProvider>
            </ThemeProvider>
        </>
    );
}

export default App;
