import { AuthenticationResult } from "@azure/msal-common";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from "@azure/msal-react";
import {
    Box,
    Button,
    Container,
    Stack,
    Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { ApiBaseUrl } from "../configuration";
import { BaseService } from "../services/base-service";
import { IUser } from "../types/IUser";

const Home = () => {
    const { instance } = useMsal();

    const loginRequest = {
        scopes: ["openid", "profile", "api://praamipiletid/read"],
    };
    const submitLogin = (authResult: AuthenticationResult) => {
        BaseService.post<IUser>(
            ApiBaseUrl + "/accounts/login",
            null,
            authResult.accessToken
        )
            .then((result) => {
                if (result.ok && result.data) {
                    toast.success("Sisselogimine õnnestus");
                } else {
                    console.log("error");
                    // TODO: show error with response
                }
            })
            .catch(() => {
                console.log("error");
                // TODO: show error on load
            });
    };
    return (
        <>
            <Helmet>
                <title>pilet.info </title>
            </Helmet>
            <Container>
                <Stack
                    marginTop={8}
                    direction="column"
                    alignItems="stretch"
                    justifyContent="space-evenly"
                    flexGrow={1}
                >
                    <Typography
                        mt={3}
                        mb={3}
                        variant="h4"
                        component="h1"
                        textAlign="center"
                    >
                        Tere tulemast
                    </Typography>
                    <AuthenticatedTemplate>
                        {/*<Stack direction="column" marginTop={8}>
                            <Typography variant="h6">
                                Muudatuste logi
                            </Typography>
                            <Box>
                                <i>09.07.2022</i> - Uuendasin kujunduse parema
                                mobiili kasutatuvuse jaoks! Kui tekib probleeme,
                                siis andke teada!
                            </Box>
    </Stack>*/}
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <Stack alignItems="center" marginTop={6}>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => {
                                    instance
                                        .loginPopup(loginRequest)
                                        .then((authResult) =>
                                            submitLogin(authResult)
                                        );
                                }}
                            >
                                Logi sisse
                            </Button>
                        </Stack>
                    </UnauthenticatedTemplate>
                </Stack>
            </Container>
        </>
    );
};

export default Home;
