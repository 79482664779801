import { PushPublicKey } from "./configuration";

const options = {
    userVisibleOnly: true,
    applicationServerKey: PushPublicKey,
};

export const unsubscribe = () => {
    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        // Do we already have a push message subscription?
        serviceWorkerRegistration.pushManager
            .getSubscription()
            .then((subscription) => {
                if (!subscription) {
                    console.log("no subscription to unsubscribe");
                    return;
                }
                subscription.unsubscribe();
                console.log("Unsubscribed");
            })
            .catch((err) => {
                console.error(`Error during getSubscription(): ${err}`);
            });
    });
};

export const requestForToken = async (): Promise<PushSubscriptionJSON> => {
    let serviceWorkerRegistration = await navigator.serviceWorker.ready;

    let subscription =
        await serviceWorkerRegistration.pushManager.getSubscription();
    if (!subscription) {
        // console.log("no subscription");
        serviceWorkerRegistration.pushManager.subscribe(options).then(
            (pushSubscription) => {
                // If subscription exists already:
                console.log("Subscription: " + pushSubscription.endpoint);
                return pushSubscription.toJSON();
            },
            (error) => {
                console.error(error);
                throw "";
            }
        );
    }
    console.log("Subscription: " + subscription!.endpoint);
    console.log(subscription!.toJSON());
    return subscription!.toJSON();
};

export const checkSubscription = async (): Promise<PushSubscriptionJSON|null> => {
  let serviceWorkerRegistration = await navigator.serviceWorker.ready;
  let subscription = await serviceWorkerRegistration.pushManager.getSubscription();

  if (!subscription) {
      return null;
  }
  return subscription!.toJSON();
};